@media (min-width: 768px) {
    body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper, body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer, body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
        transition: margin-left .3s ease-in-out;
        margin-left: 250px;
        min-height: 110px;
    }
    .navbar-expand .navbar-nav {
        display: none;
    }
}

.main-sidebar, .main-sidebar::before {
    transition: margin-left .3s ease-in-out,width .3s ease-in-out;
    width: 250px;
}

.sidebar {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.sidebar-name {
    font-size: 20px;
    font-weight: 600
}

.bg-red {
    background-color: red !important;
}

.bg-green {
    background-color: green !important;
}

.bg-orange {
    background-color: orange !important;
}

.user-panel img {
    height: auto;
    width: 4.5rem;
}

.navbar {
    padding: 1rem 0.5rem;
}

img, video {
    max-width: unset;
}

.brand-link {
    background-color: #232324;
    color: white!important;
    font-size: 1rem;
    min-height: 110px;
}

.rounded {
    border-radius: 0.5rem !important;
}

.brand-link .brand-image {
    float: none;
    line-height: .8;
    max-height: 60px;
    margin-left: 0rem;
    margin-right: 0rem;
}

.main-sidebar {
    background-color: #101010;
    position: fixed !important;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: #979797;
    color: #fff;
}

.nav-link {
    font-size: 16px!important;
    width: calc(220px - .5rem* 2)!important;
}

.nav-tabs .nav-link {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
}

.sidebar-help {
    background: white;
    border-radius: 10px;
    padding: 20px;
    margin-top: 50px;
}

.alert, .card {
    border-radius: 0.5rem;
}

.nav-pills .nav-link, .btn {
    border-radius: 0.5rem;
}

.seats-column {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.price-blur {
    filter: blur(3px);
    transition: filter 0.3s;
    cursor: pointer;
}

.price-blur:hover {
    filter: none;
}

@media only screen and (max-width: 768px) {
    .main-sidebar, .main-sidebar::before {
        width: 250px;
    }
    .sidebar-open .main-sidebar, .sidebar-open .main-sidebar::before {
        width: 250px;
    }
    .nav-link {
        width: auto!important
    }
    .nav-item {
    }
    .table-text {
        word-break: break-all;
        min-width: 300px
    }
}

@media (min-width: 426px) {
    body > div.wrapper > nav > ul {
        display: none;
    }
}

/** Change password styles **/

.custom-no-hover:hover,
.custom-no-hover:active {
    background-color: transparent !important;
    color: #fff !important;
}

.custom-no-focus:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent !important;
    color: #fff !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #000;
}

.nav-pills .nav-link:not(.active):hover {
    color: #000;
}

.tab-pane {
    border-radius: 20px!important;
  }

.dropzone .dz-preview {
    margin: 0px!important;
}

.event-body {
    vertical-align: middle!important;
}

.settings-title {
    font-size: 28px;
    font-weight: 600;
    padding-bottom: 6px;
}

.btn-orange {
    background-color: #ffc107;
    border-color: #ffc107;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(250,180,0,0.3);
    box-shadow: 0px 4px 10px 0px rgba(250,180,0,0.3);
    height: calc(2.75rem + 2px);
}

.btn-blackish {
    background-color: #000;
    border-color: #000;
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.3);
    height: calc(2.75rem + 2px);
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.btn-greyish {
    height: calc(2.75rem + 2px);
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    min-width: 40px;
}

.btn-success {
    color: #00A624;
    background-color: #9DFF8D;
    border-color: #9DFF8D;
    box-shadow: none;
    height: calc(2.75rem + 2px);
}

.form-control {
    height: calc(2.75rem + 2px);
}

.custom-select {
    height: calc(2.75rem + 2px);
}

.table-head-single {
    border-top: 0px!important;
    border-color: #000;
}

.table-text {
    word-break: break-word;
    min-width: 300px
}

.dropzone .dz-preview .dz-image {
    height: auto!important;
    width: 100%;
    margin-bottom: 20px;
}

.dropzone .dz-preview .dz-error-mark {
    position: relative;
    margin-top: 20px;
}

.dropzone .dz-preview.dz-image-preview {
    background: #ffffff00;
}

.dropzone {
    border: 0px;
    background: #E2E8F0;
    padding: 20px 20px;
    border-radius: 20px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #ffc107;
    border-color: #ffc107;
}

.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option--highlighted[aria-selected]:hover {
    background-color: #ffc107;
}

.cke_notification.cke_notification_warning {
    display: none;
}
